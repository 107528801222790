<script setup lang="ts">
import { Badge, Promotion } from "~/types";

const { t } = useT();

const props = defineProps<{
	enabled?: boolean;
	image: string;
	coins: number;
	entries: number;
	type?: Promotion["type"];
	bestDeal: boolean;
	mostPopular: boolean;
	specialOffer?: boolean;
	badge?: Badge | Record<string | number, string | number | boolean | null | undefined>;
	currencySymbol?: string;
	money?: string | number;
	subType?: string | null;
	textTooltip?: string;
}>();

const emit = defineEmits<{ (event: "click"): void }>();
const { ready: readyPopper, start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });

const handleClick = () => {
	if (!props.enabled && props.type !== "dailyWheel") {
		handleStartPopper();
		return;
	}

	emit("click");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 438,
			height: 570,
			quality: 100,
			src: image,
			format: 'webp',
			loading: 'lazy'
		}"
		width="292px"
		height="380px"
		:class="['card', `card-${type}`]"
		@click="handleClick"
	>
		<template #top>
			<MPromotionBadgeOffer
				v-if="badge"
				:badgeData="badge"
				:bestDeal="bestDeal"
				:mostPopular="mostPopular"
				textVariant="tanta"
				:size="160"
				class="label"
			/>
			<div class="box">
				<AText v-if="coins" variant="tunis" :modifiers="['bold', 'uppercase']" :class="['count', { single: !entries }]">
					<ASvg name="coins" />
					{{ numberFormat(coins) }}
				</AText>

				<AText v-if="entries" variant="tunis" :modifiers="['bold', 'uppercase']" class="count">
					<ASvg name="entries" />
					<div class="entries-text">
						<span>{{ numberFormat(entries) }}</span>
						<AText :modifiers="['semibold', 'uppercase', 'left']" class="for-free">{{ t("for free") }}</AText>
					</div>
				</AText>
			</div>
		</template>
		<template #bottom>
			<AButton v-if="enabled" variant="primary" size="xl" class="foot">
				<AText variant="turin" :modifiers="['medium']">
					<span>{{ t("buy now") }} {{ currencySymbol }}{{ money }}</span>
				</AText>
			</AButton>
			<APopper v-else :content="textTooltip" placement="top" :arrow="true" class="btn-not-allowed" :show="!readyPopper">
				<AButton variant="primary" :modifiers="['disabled']" size="xl" class="foot">
					<AText variant="turin" :modifiers="['medium']">{{ t("Available soon") }}</AText>
				</AButton>
			</APopper>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	--a-card-img-width: 292px;
	--a-card-img-height: 380px;
}
.card .badge {
	margin-top: gutter(7);
	padding-right: gutter(1);
	padding-left: gutter(1);
}

.count {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	gap: gutter(0.5);

	&.single {
		margin-bottom: gutter(5.5);
	}
}

.card:deep(img) {
	object-fit: contain;
}

.box {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	gap: gutter(0.5);

	button {
		width: 100%;
		height: 40px;
	}
}

.foot {
	width: 100%;
}

.btn-not-allowed {
	width: calc(100% + 24px);
}

.entries-text {
	display: flex;
	gap: gutter(0.5);
}

.label {
	:deep(span) {
		line-height: 25px;
	}
}

.for-free {
	width: 36px;
}
</style>
